.pagination-lg > li {
    > a, 
    span {
        line-height: 26px;
        padding: 10px 18px;
    }
} 

.pagination {
    > li {
        // overrides Bootstrap
        &:first-child,
        &:last-child {
            > a,
            > span {
                border-radius: $border-radius-base;
            }
        }

        // 
        > a {
            color: $text-color;
            border-radius: $border-radius-base;
            cursor: pointer;
        }
        > a,
        > span {
            padding: 5px 10px;
            margin-left: 10px;
        }

    }   
}

.pagination-sm {
  @include pagination-size(4px, 8px, $font-size-small, $line-height-computed, $border-radius-small);
}