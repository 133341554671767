// https://github.com/gudh/ihover

.ih-item {
    position: relative;
    border-radius: $border-radius-base;
    transition: all 0.35s ease-in-out;
    a {
        color: #333;
        &:hover {
            text-decoration: none;
        }
    }
    img {
        border-radius: $border-radius-base;
        width: 100%;
        height: 100%;
    }
}

.ih-item.ih-material {
    overflow: hidden;
    position: relative;
    width: 100%; 
    height: 100%; 

    .info-mask {
        content: '';
        position: absolute;
        top: -25%;
        left: 0;
        right: 0;
        padding-bottom: 100%;
        border-radius: 50%;
        opacity: 0;
        transform: scale(0);
        transition: all .4s cubic-bezier(.4,0,.2,1);
    }

    .info-content {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left:0;
        right: 0;
        backface-visibility: hidden;
        transform: scale(.5);
        transition: all .3s ease;
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            margin-left: -0.5em;            
        }

        .info-inner {
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            text-align: center;
            h3 {
                margin: 0 0 10px;
                text-transform: uppercase;
                color: #fff;
                font-size: 18px;
            }
            p {
                font-style: italic;
                font-size: 12px;
                color: $gray-lighter;
            }
        }
    }

    .bg-white + .info-content,
    .bg-warning + .info-content {
        .info-inner {
            h3 {
                color: $text-color;
            }
            p {
                color: #999;
            }
        }
    }

    a:hover {
        .info-content {
            opacity: 1;
            transform: scale(1);
        }
        .info-mask {
            opacity: .8;
            transform: scale(1.21); // for 200*300 images
        }
    }
}
