.ui-accordion {

    .panel-group {
        .panel-heading + .panel-collapse .panel-body {
            border-top: 1px solid $state-default-border;
        }
    }

    .panel {
        border: 1px solid $state-default-border;
    }

    .panel-heading {
        padding: 0;
        background-color: $state-default-bg;
        .panel-title > a {
            display: block;
            padding: 15px;
            font-size: 14px;
            &:hover,
            &:active,
            &:focus {
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
}

.ui-accordion-success {
    .panel {
        border: 1px solid $brand-success;
    }
    .panel-heading {
        background-color: $brand-success;
        color: $reverse;
    }
}
.ui-accordion-info {
    .panel {
        border: 1px solid $brand-info;
    }
    .panel-heading {
        background-color: $brand-info;
        color: $reverse;
    }
}
.ui-accordion-warning {
    .panel {
        border: 1px solid $brand-warning;
    }
    .panel-heading {
        background-color: $brand-warning;
        color: $reverse;
    }
}
.ui-accordion-danger {
    .panel {
        border: 1px solid $brand-danger;
    }
    .panel-heading {
        background-color: $brand-danger;
        color: $reverse;
    }
}
.ui-accordion-dark {
    .panel {
        border: 1px solid $dark;
    }
    .panel-heading {
        background-color: $dark;
        color: $reverse;
    }
}